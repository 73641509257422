import {Component, ViewContainerRef, ElementRef, Renderer} from '@angular/core';
// import {MetaService} from 'ng2-meta';
import {Store} from '@ngrx/store';

import {Config} from './shared/index';



import {AppStore} from '../core/store/store';
import {ToggleState} from '../core/store/toggle.store';
// import {Config, ToolbarComponent} from './shared/index';
// import {ResourceListComponent, ResourceDetailComponent} from './+resource/index';

// import {AppStore} from './shared/store/store';

/**
 * This class represents the main application component. Within the @Routes annotation is the configuration of the
 * applications routes, configuring the paths for the lazy loaded components (HomeComponent, AboutComponent).
 */
@Component({
  moduleId: module.id,
  selector: 'sd-app',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css']
  // directives: [ROUTER_DIRECTIVES,
  //               ToolbarComponent,
  //               ResourceListComponent,
  //               ResourceDetailComponent],
  // providers: [MetaService]
})
export class AppComponent {

  // Necessary for ng2-bootstrap to operate correctly
  viewContainerRef: any;

  constructor(viewContainerRef: ViewContainerRef,
              elementRef: ElementRef,
              renderer: Renderer,
              private store: Store<AppStore>
              // private metaService: MetaService,
              // private store: Store<AppStore>
            ) {
    this.viewContainerRef = viewContainerRef;

    /*
      This uses Angular to attach to click events on the whole document. It allows us to test whether someone has clicked on the sidebar
      or resourcestack flyouts and, if they are clicking somewhere else, then closes these flyouts.
    */
    renderer.listenGlobal('document', 'click', (event: any) => {
      let sidebarNav = document.getElementById('sidebarNav');
      let resourceStack = document.getElementById('resourceStack');
      let sharingDialog = document.getElementById('sharingDialog');
      let sharingDialogSmall = document.getElementById('sharingDialogSmall');

      let sidebarButton = document.getElementById('sidebarButton');
      // let resourceStackButton = document.getElementById('resourceStackButton');
      let resourceStackButton1 = document.getElementsByClassName('stack-toggle')[0];
      let resourceStackButton2 = document.getElementsByClassName('stack-toggle')[1];
      let sharingButton = document.getElementById('sharingButton');
      let sharingButtonSmall = document.getElementById('sharingButtonSmall');
      let removeButtons = document.getElementsByClassName('remove'); // need to also get these buttons...
      let singleButtons = document.getElementsByClassName('single'); // need to also get these buttons...

      let sidebarParents = this.getParents(event.target, sidebarNav);
      let resourceStackParents = this.getParents(event.target, resourceStack);
      let sharingDialogParents = this.getParents(event.target, sharingDialog);
      let sharingDialogSmallParents = this.getParents(event.target, sharingDialogSmall);

      console.log(event.target);
      // console.log('Parents:');
      // console.log(sidebarParents);
      // console.log(resourceStackParents);
      // console.log(sharingDialogParents);
      // //
      // console.log('In index?:');
      // console.log(sidebarParents.indexOf("sidebarNav") > -1 || sidebarParents.indexOf("resourceStack") > -1 ||
      //             resourceStackParents.indexOf("resourceStack") > -1 || resourceStackParents.indexOf("sidebarNav") > -1 ||
      //             event.target === sidebarButton ||
      //             event.target === resourceStackButton ||
      //             event.target.parentNode == sidebarButton || /* need these last two b/c Safari treats these oddly */
      //             event.target.parentNode == resourceStackButton);

      if (sidebarParents.indexOf("sidebarNav") > -1 ||
          sidebarParents.indexOf("resourceStack") > -1 ||
          sidebarParents.indexOf("sharingDialog") > -1 ||
          sidebarParents.indexOf("sharingDialogSmall") > -1 ||
          resourceStackParents.indexOf("resourceStack") > -1 ||
          resourceStackParents.indexOf("sidebarNav") > -1 ||
          resourceStackParents.indexOf("sharingDialog") > -1 ||
          resourceStackParents.indexOf("sharingDialogSmall") > -1 ||
          sharingDialogParents.indexOf("resourceStack") > -1 ||
          sharingDialogParents.indexOf("sidebarNav") > -1 ||
          sharingDialogParents.indexOf("sharingDialog") > -1 ||
          sharingDialogParents.indexOf("sharingDialogSmall") > -1 ||
          sharingDialogSmallParents.indexOf("resourceStack") > -1 ||
          sharingDialogSmallParents.indexOf("sidebarNav") > -1 ||
          sharingDialogSmallParents.indexOf("sharingDialog") > -1 ||
          sharingDialogSmallParents.indexOf("sharingDialogSmall") > -1 ||
          this.isEventInArray(event.target, removeButtons) ||
          this.isEventInArray(event.target, singleButtons) ||
          event.target === sharingDialog ||
          event.target === sharingDialogSmall ||
          event.target === sidebarButton ||
          event.target === resourceStackButton1 ||
          event.target === resourceStackButton2 ||
          event.target === sharingButton ||
          event.target === sharingButtonSmall ||
          event.target.parentNode == sidebarButton || /* need these last three b/c Safari treats these oddly */
          event.target.parentNode == resourceStackButton1 ||
          event.target.parentNode == resourceStackButton2 ||
          event.target.parentNode == sharingButton ||
          event.target.parentNode == sharingButtonSmall) {
        // Do nothing - this means we want to leave things open...
      } else {
        // Close any flyouts that are open - they've clicked on a non flyout
        this.store.dispatch({type: 'CLOSE_ALL_FLYOUTS'});
      }
    });

    // Log to the console the environment config
    console.log('Environment config', Config);
  }

  // send in the event target and an array of elements, check if the element is inside of it...
  isEventInArray(eventTarget: any, nodeArray: any) {
    for (var l = 0; l < nodeArray.length; l++) {
      if(eventTarget.parentNode.isEqualNode(nodeArray[l])) {
        return true;
      }
    }
    return false;
  }

  getParents(el: any, parentSelector: any /* optional */) {
    // If no parentSelector defined will bubble up all the way to *document*
    if (parentSelector === undefined) {
        parentSelector = document;
    }

    var parents = [];
    var p = el.parentNode;

    if (p) {
      while (p !== parentSelector && p !== null) {
          var o = p;
          if(o.getAttribute && o.getAttribute('id')) {
            parents.push(o.getAttribute('id') + ""); // convert to string...?
          }
          p = o.parentNode;
      }
      // parents.push(parentSelector); // Push that parentSelector you wanted to stop at
    }
    // console.log(parents);
    return parents;
  }
}
